<template>
    <div class="ld-container" style="height: 90vh; width: 100%">
        <div class="row" style="height: 100%">
            <div class="list-area col-md-6">
                <div class="row">
                    <header-box :title="`${$t('시스템 관리')} > ${$t('계측기유형코드 관리')}`" />
                </div>
                <div style="height: calc(100% - 40px)">
                    <table-list
                        ref="table-list"
                        :title="'계측기 유형 코드'"
                        :columns="columns"
                        :rows="meterTypeList"
                        :mode="'edit'"
                        :keyField="'meterTypeCode'"
                        :transCodes="transCodes"
                        :controls="controls"
                        :useMaxHeight="true"
                        @button-click="handleListButtonClick"
                        @row-click="handleItemClick"
                    />
                </div>
            </div>
            <div class="detail-area col-md-6">
                <MeterTypeDetail
                    ref="MeterTypeDetail"
                    :title="'계측기 유형 코드 상세'"
                    @data-updated="getMeterTypeList"
                />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderBox from "@views/component/headerBox/analysis/Header.vue";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import backEndApi from "@src/api/backEndApi";
import MeterTypeDetail from "./MeterTypeDetail.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    props: [],
    components: {
        HeaderBox,
        TableList,
        MeterTypeDetail,
    },
    data() {
        return {
            meterTypeList: [],
            columns: [
                {
                    label: this.$t("계측기 유형 코드"),
                    field: "rowTitle",
                    width: "300px",
                    type: "text",
                    compositionFields: ["meterTypeCodeName", "meterTypeCode"],
                },
                {
                    label: this.$t("설명"),
                    field: "meterTypemDetailDsc",
                    type: "text",
                    sortable: false,
                    tdClass: "left-align-wrap",
                },
                {
                    label: this.$t("사용여부"),
                    field: "enabled",
                    width: "100px",
                    type: "text",
                    sortable: false,
                },
            ],
            controls: [
                { event: "delete", label: "삭제", class: "btn-danger" },
                // { event: 'refresh', label: '갱신', class: 'bg-white text-black', },
                // { event: 'status', label: '사용', class: 'bg-white text-black', options: {enabled:"Y"}, },
                // { event: 'status', label: '미사용', class: 'bg-white text-black ', options: {enabled:"N"}, },
                { event: "regist", label: "추가", class: "btn-primary" },
            ],
            transCodes: [
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            popupMessages,
        };
    },
    watch: {},
    computed: {},
    async created() {
        await this.getMeterTypeList();
    },
    mounted() {},
    methods: {
        async getMeterTypeList() {
            let success = false;
            try {
                let result = await backEndApi.meterType.getMeterTypeList();
                if (result.status == 200) {
                    this.meterTypeList = result.data;
                    success = true;
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
            return success;
        },
        async handleItemClick(row, idx, toggle, check) {
            if (toggle || check) return;

            const confirm = await this.$refs["MeterTypeDetail"].notifyCancelEdit();
            if (confirm) {
                await this.$refs["MeterTypeDetail"].loadData(row.meterTypeCode);
            }
        },
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;

                case "delete":
                    await this.deleteChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async registNew() {
            this.$refs["MeterTypeDetail"].initData();
        },
        async deleteChecked() {
            const that = this;

            try {
                const confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                if (!confirm.value) return;

                const checked = this.$refs["table-list"].getCheckedRows();
                if (!that.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        let result = await backEndApi.meterType.deleteMeterType(item.meterTypeCode);
                        if (result.status != 200) {
                            this.alertWarning(
                                `${result.data.message}`,
                                "",
                                `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                            );
                            return;
                        }
                    });
                    await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                    let clear = await this.getMeterTypeList();
                    if (clear) await this.$refs["MeterTypeDetail"].clearData();
                    else await this.alertWarning(popupMessages.COMMON_REFRESH_ERROR_POPUP_MESSAGE);
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>

<style scoped>
.ld-container {
    height: 90vh;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    padding: 10px 30px 10px 20px;
}

.ld-container .list-area {
    height: 100%;
    overflow: hidden;
    padding: 10px;
    /* border-right: 1px solid #ececec; */
}

.ld-container .detail-area {
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-wrap: nowrap; */
    /* flex: 1; */

    height: 100%;
    margin-right: 00px;
    padding: 0px 10px 0px 10px;
    border-top: 1px solid #f4f4f4;
    border-right: 2px solid #e7e7e7;
    border-bottom: 2px solid #e7e7e7;
    border-left: 1px solid #f4f4f4;
    background-color: #f9f9f9;
    border-radius: 10px;
}
</style>
