<template>
    <div class="container" style="height: 100%">
        <div class="row flex-grow-0">
            <div class="col col-md-3">
                <label class="col" for="meterTypeCode">
                    <span>{{ $t("계측기 유형 코드") }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <input
                        v-model="detailInfo.meterTypeCode"
                        type="text"
                        :disabled="!isNewMode"
                        class="form-control"
                        id="meterTypeCode"
                        placeholder="계측기 유형 코드 입력"
                    />
                    <!-- <valid-input :vid="'공통코드'" :classList="'form-control'" :inputType="'text'"
                        :inputValue.sync="detailInfo.commonCode" :placeholder="$t('공통코드')"
                        :rules="rules.COMMON_CODE_PUBLIC_RULE"
                        :errorMessage="validMessage.COMMON_CODE_PUBLIC_VALID_MESSAGE"
                        :disabled="!isNewMode"></valid-input> -->
                </div>
            </div>
            <div class="col col-md-3">
                <label class="col" for="meterTypeCodeName">
                    <span>{{ $t("계측기 유형 코드명") }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <input
                        v-model="detailInfo.meterTypeCodeName"
                        type="text"
                        :disabled="!isEditMode"
                        class="form-control"
                        id="meterTypeCodeName"
                        placeholder="계측기 유형 코드명 입력"
                    />
                    <!-- <valid-input :vid="'공통코드 명'" :classList="'form-control'" :inputType="'text'"
                        :inputValue.sync="detailInfo.codeName" :placeholder="$t('공통코드 명')"
                        :rules="rules.COMMON_NAME_RULE" :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
                        :disabled="!isEditMode"></valid-input> -->
                </div>
            </div>
            <div class="col col-md-4">
                <label class="col" for="iconFilePath">
                    <span>{{ $t("아이콘") }}</span>
                    <!-- <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i> -->
                </label>
                <div class="col">
                    <div class="input-group">
                        <input
                            v-model="detailInfo.iconFilePath"
                            type="text"
                            :disabled="!isEditMode"
                            class="form-control"
                            id="iconFilePath"
                            placeholder="아이콘 입력"
                        />
                        <div class="input-group-append">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                @click="iconFileImport"
                                :disabled="!isEditMode"
                            >
                                {{ $t("파일 찾기") }}
                            </button>
                        </div>
                    </div>
                    <input
                        ref="iconFileImport"
                        type="file"
                        class="form-control-file"
                        accept="image/*"
                        style="display: none"
                    />
                    <!-- <valid-input :vid="'카테고리'" :classList="'form-control'" :inputType="'text'"
                        :inputValue.sync="detailInfo.codeCategory" :placeholder="$t('카테고리')"
                        :rules="rules.COMMON_CODE_PUBLIC_RULE"
                        :errorMessage="validMessage.COMMON_CODE_PUBLIC_VALID_MESSAGE"
                        :disabled="!isEditMode"></valid-input> -->
                </div>
            </div>
            <div class="col col-md-2">
                <label class="col" for="enabled">
                    <span>{{ $t("사용여부") }}</span>
                    <!-- <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i> -->
                </label>
                <div class="col">
                    <select v-model="detailInfo.enabled" :disabled="!isEditMode" id="enabled" class="form-control">
                        <option value="">선택</option>
                        <option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row flex-grow-0">
            <div class="col-md-1" style="text-align: center">
                <label for="meterTypemDetailDsc">설명</label>
            </div>
            <div class="col-md-11">
                <textarea
                    v-model="detailInfo.meterTypemDetailDsc"
                    type="text"
                    class="form-control"
                    id="meterTypemDetailDsc"
                    :disabled="!isEditMode"
                    style="resize: none; overflow-y: auto"
                    rows="3"
                ></textarea>
                <!-- <valid-textarea :inputType="'text'" :inputValue.sync="detailInfo.desc" :NonStatus="true" :rows="3"
                    :classList="'desc-ctn form-control'" :rules="rules.COMMON_DESC_RULE" :disabled="!isEditMode">
                </valid-textarea> -->
            </div>
        </div>
    </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
// import backEndApi from "@src/api/backEndApi";

export default {
    components: {},
    props: ["detailInfo", "mode"],
    data() {
        return {
            rules,
            validMessage,
        };
    },
    async created() {},
    mounted() {},
    computed: {
        isEditMode() {
            return this.mode !== "view";
        },
        isNewMode() {
            return this.mode === "new";
        },
    },
    watch: {},
    methods: {
        iconFileImport() {
            this.$refs.iconFileImport.click();
        },
    },
};
</script>

<style scoped>
.row {
    margin-bottom: 16px;
}

.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}
</style>
